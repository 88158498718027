













import {DisplayInterface} from 'App/components/util/boolean';
import {default as Vue}   from 'vue';
import {
    default as Component,
    mixins,
}                         from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            value: {
                required: true,
                type    : Boolean,
            },
        },
    },
);

@Component
export default class BooleanUtil
    extends mixins(definition)
{
    public get display(): DisplayInterface
    {
        return this.value
            ? {
                text   : 'Oui',
                variant: 'success',
            }
            : {
                text   : 'Non',
                variant: 'danger',
            };
    }
}
