




























import {client}          from 'App/dependencies/axios';
import {
    NavigationMixin,
    ViewMixin,
}                        from 'App/mixins';
import {buildUrl}        from 'App/util/helpers/urls';
import {
    DataInterface,
    ErrorComponent,
    FormComponent,
    ResultComponent,
}                        from 'App/views/search';
import {ResultInterface} from 'App/views/search/result';
import {AxiosResponse}   from 'axios';
import {default as Vue}  from 'vue';
import {
    default as Component,
    mixins,
}                        from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            ErrorComponent,
            FormComponent,
            ResultComponent,
        },
        data(): DataInterface
        {
            return {
                error : null,
                result: null,
            };
        },
    },
);

@Component
export default class SearchView
    extends mixins(
        definition,
        NavigationMixin,
        ViewMixin,
    )
{
    public created(): void
    {
        this.emitLoaded();
    }

    public async search(
        searchUrl: string,
        withoutCache: boolean,
    ): Promise<void>
    {
        this.emitLoading();

        this.result = null;

        return client
            .request<ResultInterface>(
                {
                    method: 'GET',
                    params: {
                        url: encodeURI(searchUrl),
                        withoutCache,
                    },
                    url   : buildUrl('/search'),
                },
            )
            .then(
                (
                    response: AxiosResponse<ResultInterface>,
                ): void => {
                    if (
                        response.data.folder === null
                        ||
                        response.data.match === null
                    ) {
                        const error = new Error(
                            'not found',
                        );
                        error.name = '404';

                        throw error;
                    }

                    this.result = response.data;
                    this.error = null;
                },
            )
            .catch(
                async(
                    error: Error,
                ): Promise<void> => {
                    this.result = null;
                    this.error = error;

                    return error.name === '404'
                        ? Promise.reject(error)
                        : this.$store.dispatch(
                            'error/current',
                            error,
                        );
                },
            )
            .finally(
                (): void => {
                    this.emitLoaded();
                },
            );
    }
}
