import {createGuard} from 'App/dependencies/vue/plugins/router/guard';
import {store}       from 'App/dependencies/vue/plugins/vuex';
import {
    Location,
    NavigationGuard,
    Route,
}                    from 'vue-router';

/**
 *
 * @type {}
 */
export const redirectIfAuthenticatedGuard: NavigationGuard = createGuard(
    async(
        to: Route,
    ): Promise<Location | void> => {
        return new Promise<Location | void>(
            (
                resolve: (
                    location?: Location,
                ) => void,
            ): void => {
                const isAuthenticated = store.getters['auth/isAuthenticated'] as boolean;

                if (
                    to.name === 'home'
                    &&
                    isAuthenticated
                ) {
                    const location: Location = {
                        name: 'search',
                        query: to.query
                    };

                    resolve(location);
                } else {
                    resolve();
                }
            },
        );
    },
);
