import {default as Vue}       from 'vue';
import {default as Component} from 'vue-class-component';

@Component
export class ViewMixin
    extends Vue
{
    public emitLoading(): void
    {
        this.$emit('loading');
    }

    public emitLoaded(): void
    {
        this.$emit('loaded');
    }
}
