import {ErrorResponseInterface} from 'App/dependencies/axios/interceptors/handle-error';
import {AxiosError}             from 'axios';

/**
 *
 * @param {<ErrorResponseInterface>} axiosError
 * @returns {Promise<void>}
 */
export const handleErrorInterceptor = async(
    axiosError: AxiosError<ErrorResponseInterface>,
): Promise<void> => {
    /*
     * NOTE
     *
     * transform AxiosError into standard Error
     * with Symfony error response handled
     */

    window.console.error(axiosError);
    window.console.error(axiosError.response);

    const error = new Error(
        axiosError.response?.data.detail
        ?? axiosError.response?.data.title
        ?? axiosError.message,
    );
    error.name = (axiosError.response?.status ?? 500).toString();

    return Promise.reject(error);
};
