import {library}         from '@fortawesome/fontawesome-svg-core';
import {faGitAlt}        from '@fortawesome/free-brands-svg-icons';
import {
    faCheck,
    faCopy,
    faSearch,
    faSyncAlt,
    faTimes,
}                        from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {default as Vue}  from 'vue';

library.add(
    faGitAlt,
    faCheck,
    faCopy,
    faSearch,
    faSyncAlt,
    faTimes,
);

Vue.component(
    'FontAwesomeIcon',
    FontAwesomeIcon,
);
