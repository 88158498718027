import {default as Vue}       from 'vue';
import {default as Component} from 'vue-class-component';

@Component
export class NavigationMixin
    extends Vue
{
    public async linkCount(): Promise<void>
    {
        return this.$store.dispatch(
            'linkCount',
        );
    }
}
