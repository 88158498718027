















































































































































































































































import {
    CopyUtil,
    DateTimeUtil,
    EmailUtil,
    PhoneUtil,
    SalesforceOpportunityUtil,
    UrlUtil,
}                       from 'App/components/util';
import {IconsInterface} from 'App/dependencies/vue/plugins/fontawesome';
import {storage}        from 'App/objects';
import {
    ConfigBlockInterface,
    ConfigLineInterface,
    DataInterface,
    GitInterface,
    ResultInterface,
    ScoreInterface,
    ToggleStateType,
    toogleStateKey,
}                       from 'App/views/search/result';
import {vsprintf}       from 'sprintf-js';
import {
    default as Vue,
    PropType,
}                       from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            CopyUtil,
            DateTimeUtil,
            EmailUtil,
            PhoneUtil,
            SalesforceOpportunityUtil,
            UrlUtil,
        },
        data(): DataInterface
        {
            const toggleState: ToggleStateType = storage.getItem(toogleStateKey) as ToggleStateType ?? 'parsed';

            return {
                toggleState,
                toggleStateSaved: toggleState,
            };
        },
        props     : {
            result: {
                required: true,
                type    : Object as PropType<ResultInterface>,
            },
        },
    },
);

@Component
export default class ResultComponent
    extends mixins(definition)
{
    public get configBlocks(): Array<ConfigBlockInterface>
    {
        let configBlocks: Array<ConfigBlockInterface> = [];
        const configString: string | null = this.result.folder.config;

        if (configString !== null) {
            const filterEmptyLine = (
                line: string,
            ): boolean => {
                return line.length > 0;
            };

            try {
                configBlocks =
                    configString
                        .split('\n')
                        .map(
                            (
                                line: string,
                            ): string => {
                                return line.trim();
                            },
                        )
                        .filter(filterEmptyLine)
                        .join('\n')
                        .split('[--> ')
                        .filter(filterEmptyLine)
                        .map(
                            (
                                line: string,
                            ): ConfigBlockInterface => {
                                const lines: Array<string> = ` <--]${line}`
                                    .split(' <--]')
                                    .reduce(
                                        (
                                            carry: Array<string>,
                                            line2: string,
                                        ): Array<string> => {
                                            carry.push(line2);

                                            if (carry.length > 2) {
                                                carry.shift();
                                            }

                                            return carry;
                                        },
                                        [],
                                    );

                                return {
                                    lines: lines[1]
                                        .split('\n')
                                        .filter(filterEmptyLine)
                                        .map(
                                            (
                                                line2: string,
                                            ): ConfigLineInterface => {
                                                const parts: Array<string> = line2.split(
                                                    ' :',
                                                );
                                                const text: string = parts[0];
                                                const value: string = parts[1].trim();

                                                let type: string;

                                                const iText: string = text.toLowerCase();
                                                if (value.includes('http')) {
                                                    type = 'url';
                                                } else if (
                                                    iText.includes(' passe')
                                                    ||
                                                    iText.includes('pass ')
                                                ) {
                                                    type = 'password';
                                                } else if (
                                                    (
                                                        iText.includes('email')
                                                        ||
                                                        iText.includes('mail')
                                                    )
                                                    &&
                                                    iText !== 'boîtes mail'
                                                ) {
                                                    type = 'email';
                                                } else if (iText.includes('date')) {
                                                    type = 'date';
                                                } else if (iText === 'opportunite') {
                                                    type = 'salesforce:opportunity';
                                                } else if (iText.includes('phone')) {
                                                    type = 'phone';
                                                } else {
                                                    type = 'text';
                                                }

                                                return {
                                                    text,
                                                    type,
                                                    value,
                                                };
                                            },
                                        ),
                                    title: lines[0].toLowerCase(),
                                };
                            },
                        );
            } catch (error) {
                window.console.error(error);
            }
        }

        return configBlocks;
    }

    public get folderWindows(): string
    {
        /*
         * NOTE:
         *
         * le lecteur réseau O: est attribué
         * au serveur de preprod par le service SYS
         */
        return this
            .result
            .match
            .folder
            .replace(
                '/var/www',
                'O:',
            )
            .replace(
                /\//g,
                '\\',
            );
    }

    public get git(): GitInterface
    {
        return this.result.folder.git
            ? {
                icon   : [
                    'fas',
                    'check',
                ],
                variant: 'success',
            }
            : {
                icon   : [
                    'fas',
                    'times',
                ],
                variant: 'danger',
            };
    }

    public get hasHost(): boolean
    {
        return this
                   .result
                   .query
                   .identity
                   .hostAddress !== null
               ||
               this
                   .result
                   .query
                   .identity
                   .hostName !== null;
    }

    public get hostAddress(): string
    {
        const hostAddress: string | null = this.result.query.identity.hostAddress;

        return ResultComponent.pleskUrl(hostAddress);
    }

    public get hostName(): string
    {
        const hostName: string | null = this.result.query.identity.hostName;

        return ResultComponent.pleskUrl(hostName);
    }

    public get icons(): IconsInterface
    {
        return {
            gitAlt: [
                'fab',
                'git-alt',
            ],
        };
    }

    public get score(): ScoreInterface
    {
        let score: ScoreInterface;

        switch (this.result.match.score) {
            case -2:
                score = {
                    text   : 'Preprod',
                    variant: 'info',
                };
                break;
            case -1:
                score = {
                    text   : 'Manuel',
                    variant: 'info',
                };
                break;
            case 0:
                score = {
                    text   : 'Exact',
                    variant: 'success',
                };
                break;
            case 1:
                score = {
                    text   : 'Approximatif',
                    variant: 'primary',
                };
                break;
            case 2:
                score = {
                    text   : 'Probable',
                    variant: 'warning',
                };
                break;
            case 3:
                score = {
                    text   : 'Incertain',
                    variant: 'danger',
                };
                break;
            default:
                throw new Error(
                    `unhandled score '${this.result.match.score}`,
                );
        }

        return score;
    }

    public get toggleText(): string
    {
        return this.toggleState === 'raw'
            ? 'Afficher la version interactive'
            : 'Afficher la version texte';
    }

    private static pleskUrl(
        host: string | null,
    ): string
    {
        if (host === null) {
            throw new Error(
                'hostName is null',
            );
        }

        return `https://${host}:8443`;
    }

    public copyBlock(
        block: ConfigBlockInterface,
    ): string
    {
        return block
            .lines
            .map(
                (
                    line: ConfigLineInterface,
                ): string => {
                    return vsprintf(
                        '%s : %s',
                        [
                            line.text,
                            line.value,
                        ],
                    );
                },
            )
            .join('\n');
    }

    public onToggleState(): void
    {
        this.toggleState = this.toggleState === 'raw'
            ? 'parsed'
            : 'raw';
    }

    public onSave(
        toggleState: ToggleStateType,
    ): void
    {
        storage.setItem(
            toogleStateKey,
            toggleState,
        );

        this.toggleStateSaved = toggleState;
    }
}
