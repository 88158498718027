














import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            text : {
                default: null,
                type   : String,
            },
            value: {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class UrlUtil
    extends mixins(definition)
{
    public get display(): string
    {
        return this.text ?? this.value;
    }
}
