import {GoogleUserInterface}  from 'App/dependencies/vue/plugins/google-oauth2/google-user.interface';
import {MixinInterface}       from 'App/main/the-header/the-auth';
import {default as Vue}       from 'vue';
import {default as Component} from 'vue-class-component';

@Component
export class GoogleOauth2Mixin
    extends Vue
    implements MixinInterface
{
    public async loginInner(): Promise<string>
    {
        return this
            .waitForInit()
            .then(
                async(): Promise<GoogleUserInterface> => {
                    return this
                        .$gAuth
                        .signIn();
                },
            )
            .then(
                async(
                    googleUser: GoogleUserInterface,
                ): Promise<string> => {
                    const idToken: string = googleUser.getAuthResponse().id_token;

                    return Promise.resolve(idToken);
                },
            );
    }

    public async logoutInner(): Promise<void>
    {
        return this
            .waitForInit()
            .then(
                async(): Promise<void> => {
                    return this
                        .$gAuth
                        .signOut();
                },
            );
    }

    private async waitForInit(): Promise<void>
    {
        return new Promise<void>(
            (
                resolve: () => void,
            ): void => {
                const interval: number = window.setInterval(
                    (): void => {
                        if (this.$gAuth.isInit) {
                            window.clearInterval(interval);

                            resolve();
                        }
                    },
                    100,
                );
            },
        );
    }
}
