import {StateInterface as RootStateInterface} from 'App/dependencies/vue/plugins/vuex';
import {StateInterface}                       from 'App/dependencies/vue/plugins/vuex/modules/error';
import {
    ActionContext,
    ActionHandler,
}                                             from 'vuex';

/**
 *
 * @param {string} name
 * @returns {<StateInterface, StateInterface>}
 */
export const error = (
    name: string,
): ActionHandler<StateInterface, RootStateInterface> => {
    return async(
        context: ActionContext<StateInterface, RootStateInterface>,
        payload: Error | null,
    ): Promise<void> => {
        context.commit(
            name,
            payload,
        );

        return Promise.resolve();
    };
};
