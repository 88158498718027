



























import {
    Error400Image,
    Error401Image,
    Error403Image,
    Error404Image,
    Error405Image,
    ErrorGenericImage,
    InfoInterface,
}                       from 'App/main/the-main/the-error';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            error: {
                required: true,
                type    : Error,
            },
        },
    },
);

@Component
export default class TheErrorComponent
    extends mixins(
        definition,
    )
{
    public get info(): InfoInterface
    {
        let info: InfoInterface;

        let name: string = this.error.name;

        switch (name) {
            case '400':
            case '412':
                info = {
                    image: {
                        alt: 'pirates of the caribbean meme',
                        src: Error400Image,
                    },
                };
                break;
            case '401':
                info = {
                    image: {
                        alt: 'jurassic park meme',
                        src: Error401Image,
                    },
                };
                break;
            case '403':
                info = {
                    image: {
                        alt: 'the lord of the rings meme',
                        src: Error403Image,
                    },
                };
                break;
            case '404':
                info = {
                    image: {
                        alt: 'pulp fiction meme',
                        src: Error404Image,
                    },
                };
                break;
            case '405':
                info = {
                    image: {
                        alt: 'the matrix meme',
                        src: Error405Image,
                    },
                };
                break;
            default:
                info = {
                    image: {
                        alt: 'picard face palm meme',
                        src: ErrorGenericImage,
                    },
                };
        }

        return info;
    }

    public get reason(): string
    {
        return this.error.message;
    }

    public get title(): string
    {
        const titles: Record<string, string> = {
            '400': 'Bad Request',
            '401': 'Unauthorized',
            '402': 'Payment Required',
            '403': 'Forbidden',
            '404': 'Not Found',
            '405': 'Method Not Allowed',
            '406': 'Not Acceptable',
            '407': 'Proxy Authentication Required',
            '408': 'Request Timeout',
            '409': 'Conflict',
            '410': 'Gone',
            '411': 'Length Required',
            '412': 'Precondition Failed',
            '413': 'Request Entity Too Large',
            '414': 'Request-URI Too Long',
            '415': 'Unsupported Media Type',
            '416': 'Requested Range Not Satisfiable',
            '417': 'Expectation Failed',
            '426': 'Upgrade Required',
            '428': 'Precondition Required',
            '429': 'Too Many Requests',
            '431': 'Request Header Fields Too Large',
            '451': 'Unavailable For Legal Reasons',
            '500': 'Internal Server Error',
            '501': 'Not Implemented',
            '502': 'Bad Gateway',
            '503': 'Service Unavailable',
            '504': 'Gateway Timeout',
            '505': 'HTTP Version Not Supported',
            '510': 'Not Extended',
            '511': 'Network Authentication Required',
            '598': 'Network read timeout error',
            '599': 'Network connect timeout error',
        };

        return titles[this.error.name] ?? this.error.name;
    }

}
