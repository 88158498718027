import {sessionKey} from 'App/dependencies/vue/plugins/vuex/modules/auth/session.key';
import {storage}    from 'App/objects';

/**
 *
 * @returns {Promise<string>}
 */
export const fetchFromSession = async(): Promise<string> => {
    return new Promise<string>(
        (
            resolve: (
                jsonWebToken: string,
            ) => void,
            reject: () => void,
        ): void => {
            const jwt: string | null = storage.getItem(sessionKey);

            if (jwt === null) {
                reject();
            } else {
                resolve(jwt);
            }
        },
    );
};

/**
 *
 * @param {string} jwt
 * @returns {Promise<void>}
 */
export const saveIntoSession = async(
    jwt: string,
): Promise<void> => {
    return new Promise<void>(
        (
            resolve: () => void,
        ): void => {
            storage.setItem(
                sessionKey,
                jwt,
            );

            resolve();
        },
    );
};

/**
 *
 * @returns {Promise<void>}
 */
export const deleteFromSession = async(): Promise<void> => {
    return new Promise<void>(
        (
            resolve: () => void,
        ): void => {
            storage.removeItem(sessionKey);

            resolve();
        },
    );
};
