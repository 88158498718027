import {User}                 from 'App/dependencies/vue/plugins/vuex/modules/auth/user';
import {default as Vue}       from 'vue';
import {default as Component} from 'vue-class-component';

@Component
export class AuthMixin
    extends Vue
{
    public get isAuthenticated(): boolean
    {
        return this.$store.getters['auth/isAuthenticated'];
    }

    public get user(): User
    {
        return this.$store.getters['auth/user'];
    }
}
