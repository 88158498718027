import {GlobalsInterface}   from 'App/dependencies/vue/plugins/vuex/modules/globals';
import {default as dotProp} from 'dot-prop';

export class Globals
{
    private readonly globals: GlobalsInterface;

    /**
     *
     * @param {GlobalsInterface} globals
     */
    public constructor(
        globals: GlobalsInterface,
    )
    {
        this.globals = globals;
    }

    /**
     *
     * @param {string} key
     * @returns {T}
     */
    public getNode<T = string>(
        key: string,
    ): T
    {
        const globals: T | undefined = dotProp.get<T>(
            this.globals,
            key,
        );
        if (globals === undefined) {
            throw new Error(
                `Globals('${key}') not set`,
            );
        }

        return globals;
    }
}
