import {
    cancelToken,
    cancelTokens,
}                           from 'App/dependencies/axios/interceptors/add-cancel-token/cancel-token';
import {AxiosRequestConfig} from 'axios';

/**
 *
 * @param {} requestConfig
 * @returns {Promise<>}
 */
export const addCancelTokenInterceptor = async(
    requestConfig: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
    /*
     * NOTE
     *
     * DO NOT add cancelToken if one already exists
     */
    if (requestConfig.cancelToken === undefined) {
        const source = cancelToken.source();

        requestConfig.cancelToken = source.token;

        cancelTokens.push(source);
    }

    return Promise.resolve(requestConfig);
};
