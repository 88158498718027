import {StateInterface} from 'App/dependencies/vue/plugins/vuex';
import {
    authModule,
    errorModule,
    globalsModule,
}                       from 'App/dependencies/vue/plugins/vuex/modules';
import {
    ActionContext,
    Store,
}                       from 'vuex';

/**
 *
 */
export const store: Store<StateInterface> = new Store<StateInterface>(
    {
        actions  : {
            linkCount: async(
                context: ActionContext<StateInterface, StateInterface>,
            ): Promise<void> => {
                context.commit(
                    'linkCount',
                    context.state.linkCount + 1,
                );

                return Promise.resolve();
            },
        },
        getters  : {
            linkCount: (
                state: StateInterface,
            ): number => {
                return state.linkCount;
            },
        },
        modules  : {
            auth   : authModule,
            error  : errorModule,
            globals: globalsModule,
        },
        mutations: {
            linkCount: (
                state: StateInterface,
                payload: number,
            ): void => {
                state.linkCount = payload;
            },
        },
        state    : {
            linkCount: 0,
        },
    },
);
