import {cancelTokens}      from 'App/dependencies/axios/interceptors/add-cancel-token/cancel-token';
import {createGuard}       from 'App/dependencies/vue/plugins/router/guard';
import {CancelTokenSource} from 'axios';
import {NavigationGuard}   from 'vue-router';

/**
 *
 * @type {}
 */
export const cancelRequestsGuard: NavigationGuard = createGuard(
    async(): Promise<void> => {
        return new Promise<void>(
            (
                resolve: () => void,
            ): void => {
                let cancelToken: CancelTokenSource | undefined;

                while (cancelTokens.length > 0) {
                    cancelToken = cancelTokens.shift();

                    if (cancelToken !== undefined) {
                        cancelToken.cancel(
                            'Navigation triggered',
                        );
                    }
                }

                resolve();
            },
        );
    },
);
