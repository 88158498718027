import {buildUrl as baseBuildUrl} from 'App/util/helpers/urls';

/**
 *
 * @param {string} path
 * @returns {string}
 */
export const buildUrl = (
    path: string = '',
): string => {
    return baseBuildUrl(`/auth/${path}`);
};
