













import {
    CountryCode,
    parsePhoneNumberFromString,
    PhoneNumber,
}                       from 'libphonenumber-js';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        props     : {
            countryCode: {
                default: 'FR',
                type   : String,
            },
            value      : {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class PhoneUtil
    extends mixins(definition)
{
    public get display(): string
    {
        const phone = this.phone();

        return phone === undefined
            ? this.value
            : phone.formatNational();
    }

    public get uri(): string | null
    {
        const phone = this.phone();

        return phone === undefined
            ? null
            : phone.getURI();
    }

    private phone(): PhoneNumber | undefined
    {
        return parsePhoneNumberFromString(
            this.value,
            this.countryCode as CountryCode,
        );
    }
}
