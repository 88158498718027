import {Globals}        from 'App/dependencies/vue/plugins/vuex/modules/globals';
import {default as Vue} from 'vue';
import {default as Component} from 'vue-class-component';

@Component
export class GlobalsMixin
    extends Vue
{
    public get globals(): Globals
    {
        return this.$store.getters['globals/globals'];
    }
}
