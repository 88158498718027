import {NavigationGuardType} from 'App/dependencies/vue/plugins/router';
import {store}               from 'App/dependencies/vue/plugins/vuex';
import {
    NavigationGuard,
    NavigationGuardNext,
    Location,
    Route,
}                            from 'vue-router';

/**
 *
 * @param {} navigationGuard
 * @returns {}
 */
export const createGuard = (
    navigationGuard: NavigationGuardType,
): NavigationGuard => {
    return async(
        to: Route,
        from: Route,
        next: NavigationGuardNext,
    ): Promise<void> => {
        return navigationGuard(
            to,
            from,
        )
            .catch(
                async(
                    error: Error,
                ): Promise<void> => {
                    return store
                        .dispatch(
                            'error/next',
                            error,
                        );
                },
            )
            .then(
                (
                    nextTo: Location | void,
                ): void => {
                    next(nextTo);
                },
            );
    };
};
