












import {
    TheFooterComponent,
    TheHeaderComponent,
    TheMainComponent,
}                       from 'App/main';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            TheFooterComponent,
            TheHeaderComponent,
            TheMainComponent,
        },
    },
);

@Component
export default class AppEntry
    extends mixins(definition)
{
}
