

















import {MessageUtil} from 'App/components/util';
import {MessageInterface} from 'App/components/util/message';
import {
    default as Vue,
    PropType,
} from 'vue';
import {
    default as Component,
    mixins,
} from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            MessageUtil,
        },
        props     : {
            messages: {
                required: true,
                type    : Array as PropType<Array<MessageInterface>>,
            },
        },
    },
);

@Component
export default class MessagesUtil
    extends mixins(definition)
{
}
