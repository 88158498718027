import {InfoView} from 'App/views/auth';

/**
 *
 * @type {{path: string, component: InfoView, meta: {authenticated: boolean}, name: string}[]}
 */
export const authRoutes = [
    {
        component: InfoView,
        meta     : {
            authenticated: true,
        },
        name     : 'info',
        path     : '/info',
    },
];
