import {client}                               from 'App/dependencies/axios';
import {StateInterface as RootStateInterface} from 'App/dependencies/vue/plugins/vuex';
import {
    buildUrl,
    deleteFromSession,
    fetchFromSession,
    RequestInfoInterface,
    StateInterface,
}                                             from 'App/dependencies/vue/plugins/vuex/modules/auth';
import {UserInterface}                        from 'App/dependencies/vue/plugins/vuex/modules/auth/user';
import {User}                                 from 'App/dependencies/vue/plugins/vuex/modules/auth/user/user';
import {AxiosResponse}                        from 'axios';
import {
    ActionContext,
    Module,
}                                             from 'vuex';

/**
 *
 * @type {{mutations: {jwt: (state: StateInterface, payload: NullableType<string>) => void}, state: {jwt: null}, getters: {user: (state: StateInterface) => NullableType<User>}, actions: {init: (context: <StateInterface, StateInterface>) => Promise<void>, logout: (context: <StateInterface, StateInterface>) => Promise<void>, login: (context: <StateInterface, StateInterface>, payload: string) => Promise<void>}, namespaced: boolean}}
 */
export const authModule: Module<StateInterface, RootStateInterface> = {
    actions   : {
        init: async(
            context: ActionContext<StateInterface, RootStateInterface>,
        ): Promise<void> => {
            return fetchFromSession()
                .then(
                    async(
                        jwt: string,
                    ): Promise<AxiosResponse<RequestInfoInterface>> => {
                        client.defaults.headers.common['X-API-JWT'] = jwt;

                        return client
                            .request<RequestInfoInterface>(
                                {
                                    url: buildUrl('/info'),
                                },
                            );
                    },
                )
                .then(
                    (
                        response: AxiosResponse<RequestInfoInterface>,
                    ): void => {
                        const user: UserInterface = JSON.parse(response.data.user);

                        context.commit(
                            'user',
                            user,
                        );
                    },
                )
                .catch(
                    async(): Promise<void> => {
                        context.commit(
                            'user',
                            null,
                        );

                        client.defaults.headers.common['X-API-JWT'] = null;

                        return deleteFromSession();
                    },
                );
        },
    },
    getters   : {
        isAuthenticated: (
            state: StateInterface,
        ): boolean => {
            return state.user !== null;
        },
        user           : (
            state: StateInterface,
        ): User => {
            if (state.user === null) {
                const error = new Error(
                    'anonymous',
                );

                error.name = '401';

                throw error;
            }

            return new User(state.user);
        },
    },
    mutations : {
        user: (
            state: StateInterface,
            payload: UserInterface | null,
        ): void => {
            state.user = payload;
        },
    },
    namespaced: true,
    state     : {
        user: null,
    },
};
