import {default as Vue}   from 'vue';
import {default as GAuth} from 'vue-google-oauth2';

Vue.use(
    GAuth,
    {
        clientId: '375109351023-cirr1pod5tqj4p8a0vlomvnv03uon1qh.apps.googleusercontent.com',
        prompt  : 'consent',
        scope   : 'profile email',
    },
);
