import {createGuard} from 'App/dependencies/vue/plugins/router/guard';
import {
    NavigationGuard,
    Route,
}                    from 'vue-router';

/**
 *
 * @type {}
 */
export const notFoundGuard: NavigationGuard = createGuard(
    async(
        to: Route,
    ): Promise<void> => {
        return new Promise<void>(
            (
                resolve: () => void,
                reject: (
                    error: Error,
                ) => void,
            ): void => {
                if (to.name === 'catch_all') {
                    const error = new Error(
                        `URL not found: '${to.fullPath}'`,
                    );
                    error.name = '404';

                    reject(error);
                } else {
                    resolve();
                }
            },
        );
    },
);
