











import {default as moment} from 'moment';
import {default as Vue}    from 'vue';
import {
    default as Component,
    mixins,
}                          from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            formatIn : {
                default: 'Y-MM-DD HH:mm:ss',
                type   : String,
            },
            formatOut: {
                default: 'Y-MM-DD HH:mm:ss',
                type   : String,
            },
            value    : {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class DateTimeUtil
    extends mixins(definition)
{
    public get text(): string
    {
        return moment(
            this.value,
            this.formatIn,
        )
            .format(this.formatOut);
    }
}
