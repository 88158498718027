












































import {RoutesInterface} from 'App/dependencies/vue/plugins/router';
import {default as Vue}  from 'vue';
import {
    default as Component,
    mixins,
}                        from 'vue-class-component';

const definition = Vue.extend();

@Component
export default class TheFooterComponent
    extends mixins(
        definition,
    )
{

    public get routes(): RoutesInterface
    {
        return {
            home: {
                name: 'home',
            },
        };
    }
}
