import {AfterGuardType} from 'App/dependencies/vue/plugins/router';
import {store}   from 'App/dependencies/vue/plugins/vuex';
import {Globals} from 'App/dependencies/vue/plugins/vuex/modules/globals';
import {Route}   from 'vue-router';

/**
 *
 * @param {} to
 * @returns {Promise<void>}
 */
export const updateTitleGuard: AfterGuardType<Promise<void>> = async(
    to: Route,
): Promise<void> => {
    return new Promise<void>(
        (
            resolve: () => void,
        ): void => {
            const globals: Globals = store.getters['globals/globals'];

            const appName: string = globals.getNode('app.name');
            const appVersion: string = globals.getNode('app.version');
            const page: string = to.name ?? 'unknown';

            window.document.title = `${appName} v${appVersion} - ${page}`;

            resolve();
        },
    );
};
