import {client}                               from 'App/dependencies/axios';
import {StateInterface as RootStateInterface} from 'App/dependencies/vue/plugins/vuex';
import {
    Globals,
    GlobalsInterface,
    GlobalsRequestInterface,
    RequestInterface,
    StateInterface,
}                                             from 'App/dependencies/vue/plugins/vuex/modules/globals';
import {buildUrl}                             from 'App/util/helpers/urls';
import {AxiosResponse}                        from 'axios';
import {default as dotProp}                   from 'dot-prop';
import {
    ActionContext,
    Module,
}                                             from 'vuex';

/**
 *
 * @type {{mutations: {globals: (state: StateInterface, payload: GlobalsInterface) => void}, state: {globals: null}, getters: {globals: (state: StateInterface) => GlobalsInterface}, actions: {init: (context: <StateInterface, StateInterface>) => Promise<void>}, namespaced: boolean}}
 */
export const globalsModule: Module<StateInterface, RootStateInterface> = {
    actions   : {
        init: async(
            context: ActionContext<StateInterface, RootStateInterface>,
        ): Promise<void> => {
            return client
                .request<RequestInterface>(
                    {
                        method: 'GET',
                        url   : buildUrl('/globals'),
                    },
                )
                .then(
                    (
                        response: AxiosResponse<RequestInterface>,
                    ): void => {
                        const globals: GlobalsRequestInterface = response.data.globals;
                        const obj: Record<string, unknown> = {};

                        Object
                            .entries(globals)
                            .forEach(
                                (
                                    entry: [string, unknown],
                                ): void => {
                                    const hasOwnProperty = Object.prototype.hasOwnProperty.call(
                                        globals,
                                        entry[0],
                                    );
                                    if (hasOwnProperty) {
                                        dotProp.set(
                                            obj,
                                            entry[0],
                                            entry[1],
                                        );
                                    }
                                },
                            );

                        context.commit(
                            'globals',
                            obj,
                        );
                    },
                );
        },
    },
    getters   : {
        globals: (
            state: StateInterface,
        ): Globals => {
            if (state.globals === null) {
                throw new Error(
                    'globals is null',
                );
            }

            return new Globals(state.globals);
        },
    },
    mutations : {
        globals: (
            state: StateInterface,
            payload: GlobalsInterface,
        ): void => {
            state.globals = payload;
        },
    },
    namespaced: true,
    state     : {
        globals: null,
    },
};
