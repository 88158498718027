


















import {ToastPropertyInterface} from 'App/components/util/copy';
import {IconType}               from 'App/dependencies/vue/plugins/fontawesome';
import {
    default as Vue,
    PropType,
}                               from 'vue';
import {
    default as Component,
    mixins,
}                               from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            text        : {
                default: 'Copier',
                type   : String,
            },
            toastFailure: {
                default: undefined,
                type   : Object as PropType<Partial<ToastPropertyInterface>>,
            },
            toastSuccess: {
                default: undefined,
                type   : Object as PropType<Partial<ToastPropertyInterface>>,
            },
            value       : {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class CopyUtil
    extends mixins(definition)
{
    public get icon(): IconType
    {
        return [
            'fas',
            'copy',
        ];
    }

    public onCopySuccess(): void
    {
        this.$bvToast.toast(
            this.toastSuccess?.text ?? 'Réussite',
            {
                autoHideDelay: this.toastSuccess?.delay ?? 666,
                title        : this.toastSuccess?.title ?? 'Copie',
                variant      : this.toastSuccess?.variant ?? 'primary',
            },
        );
    }

    public onCopyFailure(): void
    {
        this.$bvToast.toast(
            this.toastFailure?.text ?? 'Echec',
            {
                autoHideDelay: this.toastFailure?.delay ?? 4200,
                title        : this.toastFailure?.title ?? 'Copie',
                variant      : this.toastFailure?.variant ?? 'danger',
            },
        );
    }
}
