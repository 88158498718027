import {buildRoutes} from 'App/dependencies/vue/plugins/router/build-routes';
import {authRoutes}  from 'App/dependencies/vue/plugins/router/routes';
import {
    HomeView,
    SearchView,
}                    from 'App/views';
import {RouteConfig} from 'vue-router';

/**
 *
 * @type {({path: string, component: HomeView, name: string} | {path: string, component: SearchView, meta: {authenticated: boolean}, name: string} |  | {path: string, component: HomeView, name: string})[]}
 */
export const baseRoutes: Array<RouteConfig> = [
    {
        component: HomeView,
        name     : 'home',
        path     : '/',
    },
    {
        component: SearchView,
        meta     : {
            authenticated: true,
        },
        name     : 'search',
        path     : '/search',
    },
    ...buildRoutes(
        authRoutes,
        {
            meta      : {
                authenticated: true,
            },
            namePrefix: 'auth_',
            pathPrefix: '/auth',
        },
    ),
    {
        component: HomeView,
        name     : 'catch_all',
        path     : '*',
    },
];
