import {UserInterface} from 'App/dependencies/vue/plugins/vuex/modules/auth/user';

export class User
{
    private readonly user: UserInterface;

    public constructor(
        user: UserInterface,
    )
    {
        this.user = user;
    }

    public get data(): UserInterface
    {
        return this.user;
    }
}

