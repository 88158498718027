











import {ViewMixin} from 'App/mixins';
import {
    TheHeaderComponent,
    TheMainComponent,
} from 'App/views/auth/info';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
} from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            TheMainComponent,
            TheHeaderComponent,
        },
    },
);

@Component
export default class InfoView
    extends mixins(
        definition,
        ViewMixin,
    )
{
    public created(): void
    {
        this.emitLoaded();
    }
}
