import {
    addCancelTokenInterceptor,
    handleErrorInterceptor,
} from 'App/dependencies/axios/interceptors';
import {
    AxiosInstance,
    default as Axios,
} from 'axios';

/**
 *
 */
export const client: AxiosInstance = Axios.create(
    {
        headers: {
            'Accept'          : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
    },
);

client
    .interceptors
    .request
    .use(
        addCancelTokenInterceptor,
    );

client
    .interceptors
    .response
    .use(
        undefined,
        handleErrorInterceptor,
    );
