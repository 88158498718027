












import {MessageInterface} from 'App/components/util/message';
import {
    default as Vue,
    PropType,
} from 'vue';
import {
    default as Component,
    mixins,
} from 'vue-class-component';

const definition = Vue.extend(
    {
        props   : {
            message: {
                required: true,
                type    : Object as PropType<MessageInterface>,
            },
        },
    },
);

@Component
export default class MessageUtil
    extends mixins(definition)
{
    public get variant(): string
    {
        let variant: string;

        switch (this.message.level) {
            case 'success':
                variant = 'success';
                break;
            case 'warning':
                variant = 'warning';
                break;
            case 'error':
                variant = 'danger';
                break;
            default:
                throw new Error(
                    `Unhandled level ${this.message.level}`,
                );
        }

        return variant;
    }
}
