











import {StalactitePostInterface} from 'App/dependencies/vue/plugins/vuex/modules/auth/user';
import {DataInterface}           from 'App/views/auth/info/the-main/posts';
import {
    default as Vue,
    PropType,
}                                from 'vue';
import {
    default as Component,
    mixins,
}                                from 'vue-class-component';

const definition = Vue.extend(
    {
        data(): DataInterface
        {
            return {
                fields: [
                    {
                        key  : 'name',
                        label: 'nom',
                    },
                    {
                        key  : 'shortName',
                        label: 'symbole',
                    },
                ],
            };
        },
        props     : {
            items: {
                required: true,
                type    : Array as PropType<Array<StalactitePostInterface>>,
            },
        },
    },
);

@Component
export default class PostsComponent
    extends mixins(definition)
{
}
