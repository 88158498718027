import {baseRoutes}           from 'App/dependencies/vue/plugins/router/base.routes';
import {
    cancelRequestsGuard,
    isAuthenticatedGuard,
    notFoundGuard,
    redirectIfAuthenticatedGuard,
    syncErrorGuard,
    updateTitleGuard,
}                             from 'App/dependencies/vue/plugins/router/guards';
import {default as VueRouter} from 'vue-router';

/**
 *
 * @type {VueRouter}
 */
export const router: VueRouter = new VueRouter(
    {
        mode  : 'history',
        routes: baseRoutes,
    },
);

router.beforeEach(cancelRequestsGuard);
router.beforeEach(notFoundGuard);
router.beforeEach(redirectIfAuthenticatedGuard);
router.beforeEach(isAuthenticatedGuard);
router.afterEach(syncErrorGuard);
router.afterEach(updateTitleGuard);
