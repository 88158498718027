import {AfterGuardType} from 'App/dependencies/vue/plugins/router';
import {store}          from 'App/dependencies/vue/plugins/vuex';

/**
 *
 * @returns {Promise<void>}
 */
export const syncErrorGuard: AfterGuardType<Promise<void>> = async(): Promise<void> => {
    return new Promise<void>(
        (
            resolve: () => void,
        ): void => {
            store
                .dispatch('error/sync')
                .finally(
                    (): void => {
                        resolve();
                    },
                );
        },
    );
};
