








































import {client}            from 'App/dependencies/axios';
import {GoogleOauth2Mixin} from 'App/dependencies/vue/plugins/google-oauth2';
import {
    ResolveRouteInterface,
    RoutesInterface,
}                          from 'App/dependencies/vue/plugins/router';
import {
    buildUrl,
    deleteFromSession,
    RequestLoginInterface,
    saveIntoSession,
}                          from 'App/dependencies/vue/plugins/vuex/modules/auth';
import {DataInterface}     from 'App/main/the-header/the-auth';
import {
    AuthMixin,
    NavigationMixin,
}                          from 'App/mixins';
import {AxiosResponse}     from 'axios';
import {default as Vue}    from 'vue';
import {
    default as Component,
    mixins,
}                          from 'vue-class-component';

const definition = Vue.extend(
    {
        data(): DataInterface
        {
            return {
                disabled: false,
            };
        },
    },
);

@Component
export default class TheAuthComponent
    extends mixins(
        definition,
        AuthMixin,
        GoogleOauth2Mixin,
        NavigationMixin,
    )
{
    public get routes(): RoutesInterface
    {
        return {
            home: {
                name: 'home',
            },
            info: {
                name: 'auth_info',
            },
        };
    }

    public async login(): Promise<void>
    {
        return this.handle(
            async(): Promise<void> => {
                return this
                    .loginInner()
                    .then(
                        async(
                            idToken: string,
                        ): Promise<AxiosResponse<RequestLoginInterface>> => {
                            return client.request<RequestLoginInterface>(
                                {
                                    data  : {
                                        externalUserJwt: idToken,
                                    },
                                    method: 'POST',
                                    url   : buildUrl('/login'),
                                },
                            );
                        },
                    )
                    .then(
                        async(
                            response: AxiosResponse<RequestLoginInterface>,
                        ): Promise<void> => {
                            const jwt: string = response.data.jwt;

                            return saveIntoSession(jwt);
                        },
                    )
                    .catch(
                        (
                            reason: unknown,
                        ): never => {
                            const message = reason instanceof Error
                                ? reason.message
                                : JSON.stringify(reason);

                            const error = new Error(
                                message,
                            );
                            error.name = '401';

                            throw error;
                        },
                    )
                    .then(
                        (): void => {
                            this.$router.go(0);
                        },
                    );
            },
        );
    }

    public async logout(): Promise<void>
    {
        return this.handle(
            async(): Promise<void> => {
                return this
                    .logoutInner()
                    .then(deleteFromSession)
                    .then(
                        (): void => {
                            const route: ResolveRouteInterface = this
                                .$router
                                .resolve(this.routes.home);

                            window.location.href = route.href;
                        },
                    );
            },
        );
    }

    private async handle(
        promise: () => Promise<void>,
    ): Promise<void>
    {
        this.disabled = true;

        return promise()
            .catch(
                async(
                    error: unknown,
                ): Promise<void> => {
                    let promise: Promise<void>;

                    if (error instanceof Error) {
                        promise = this.$store.dispatch(
                            'error/current',
                            error,
                        );
                    } else {
                        promise = Promise.reject(error);
                    }

                    return promise
                        .finally(
                            (): void => {
                                throw error;
                            },
                        );
                },
            )
            .finally(
                (): void => {
                    this.disabled = false;
                },
            );
    }
}
