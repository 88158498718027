

























import {
    GlobalsMixin,
    ViewMixin,
}                       from 'App/mixins';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend();

@Component
export default class HomeView
    extends mixins(
        definition,
        GlobalsMixin,
        ViewMixin,
    )
{
    public created(): void
    {
        this.emitLoaded();
    }
}
