import {default as MainEntry} from 'App/main.entry.vue';
import 'App/dependencies';
import {router}              from 'App/dependencies/vue/plugins/router';
import {store}               from 'App/dependencies/vue/plugins/vuex';
import {sessionKey}          from 'App/dependencies/vue/plugins/vuex/modules/auth';
import 'App/styles/main.entry';
import {toogleStateKey}      from 'App/views/search/result';
import {
    CreateElement,
    default as Vue,
    VNode,
}                            from 'vue';

const id: string = 'vue-app';
const element = window.document.getElementById(id);
if (element === null) {
    throw new Error(
        `Element '${id}' does not exist`,
    );
}

Promise
    .all(
        [
            store.dispatch('auth/init'),
            store.dispatch('globals/init'),
        ],
    )
    .then(
        (): void => {
            const storage: Storage = localStorage;

            let storageKeys: Array<string> = [];
            let storageKey: string | null;

            for (let i = 0, max = storage.length; i < max; i += 1) {
                storageKey = storage.key(i);
                if (storageKey !== null) {
                    storageKeys.push(storageKey);
                }
            }

            storageKeys = storageKeys.filter(
                (
                    key: string,
                ): boolean => {
                    return ![
                        sessionKey,
                        toogleStateKey,
                    ].includes(key);
                },
            );

            storageKeys.forEach(
                (
                    key: string,
                ): void => {
                    storage.removeItem(key);
                },
            );
        },
    )
    .then(
        (): void => {
            new Vue(
                {
                    el        : element,
                    render    : (
                        createElement: CreateElement,
                    ): VNode => {
                        return createElement(MainEntry);
                    },
                    router,
                    store,
                    template  : '<MainEntry />',
                },
            );
        },
    )
    .catch(
        (
            error: unknown,
        ): void => {
            window.console.error(error);

            element.innerHTML = 'Erreur lors du chargement des données';
        },
    );
