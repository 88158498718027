












































import {
    DataInterface,
    TheErrorComponent,
}                       from 'App/main/the-main';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            TheErrorComponent,
        },
        data(): DataInterface
        {
            return {
                isLoading: true,
            };
        },
        watch     : {
            '$route': 'loading',
        },
    },
);

@Component
export default class TheMainComponent
    extends mixins(definition)
{
    public get currentError(): Error | null
    {
        return this.$store.getters['error/current'] as Error | null;
    }

    public get key(): string
    {
        const linkCount: number = this.$store.getters.linkCount;
        const fullPath: string = this.$route.fullPath;

        return `${linkCount}${fullPath}`;
    }

    public loading(): void
    {
        this.isLoading = true;
    }

    public loaded(): void
    {
        this.isLoading = false;
    }
}
