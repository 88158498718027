import {ImportConfigInterface} from 'App/dependencies/vue/plugins/router';
import {default as clone}      from 'clone';
import {RouteConfig}           from 'vue-router';

/**
 *
 * @param {Array<>} routes
 * @param {ImportConfigInterface} importConfig
 * @returns {Array<>}
 */
export const buildRoutes = (
    routes: Array<RouteConfig>,
    importConfig?: ImportConfigInterface,
): Array<RouteConfig> => {
    const config: ImportConfigInterface = importConfig ?? {};

    const recursiveMap = (
        route: RouteConfig,
    ): RouteConfig => {
        const clonedRoute: RouteConfig = clone<RouteConfig>(route);

        clonedRoute.meta = config.meta ?? {};
        clonedRoute.name = `${config.namePrefix ?? ''}${clonedRoute.name ?? ''}`;
        clonedRoute.path = `${config.pathPrefix ?? ''}${clonedRoute.path}`.replace(
            /\/+/g,
            '/',
        );

        const metas: Record<string, unknown> = clonedRoute.meta ?? {};
        Object
            .entries(metas)
            .forEach(
                (
                    entry: [string, unknown],
                ): void => {
                    clonedRoute.meta[entry[0]] = entry[1];
                },
            );

        return clonedRoute;
    };

    return routes.map(recursiveMap);
};
