












import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        props: {
            value: {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class EmailUtil
    extends mixins(definition)
{
}
