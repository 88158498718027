











import {RoutesInterface} from 'App/dependencies/vue/plugins/router';
import {
    GlobalsMixin,
    NavigationMixin,
}                        from 'App/mixins';
import {default as Vue}  from 'vue';
import {
    default as Component,
    mixins,
}                        from 'vue-class-component';

const definition = Vue.extend();

@Component
export default class TheBrandComponent
    extends mixins(
        definition,
        GlobalsMixin,
        NavigationMixin,
    )
{
    public get routes(): RoutesInterface
    {
        return {
            brand: {
                name: 'home',
            },
        };
    }
}
