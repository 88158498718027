
















































import {client}         from 'App/dependencies/axios';
import {
    DataInterface as AutosuggestDataInterface,
    ResultInterface as AutosuggestSuggestionInterface,
}                       from 'App/dependencies/vue/plugins/autosuggest';
import {IconsInterface} from 'App/dependencies/vue/plugins/fontawesome';
import {MatchInterface} from 'App/interfaces';
import {voidPromise}    from 'App/util';
import {buildUrl}       from 'App/util/helpers/urls';
import {DataInterface}  from 'App/views/search/form';
import {
    AxiosResponse,
    default as Axios,
}                       from 'axios';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        data(): DataInterface
        {
            const url = this.$route.query.url as string | null;

            return {
                cancelTokenDatalist: null,
                suggestions        : [],
                url                : url ?? '',
                urlDefault         : url ?? '',
                withoutCache       : false,
            };
        },
    },
);

@Component
export default class FormComponent
    extends mixins(
        definition,
    )
{
    public readonly $refs!: {
        submit: HTMLButtonElement;
    };

    public get icons(): IconsInterface
    {
        return {
            search : [
                'fas',
                'search',
            ],
            syncAlt: [
                'fas',
                'sync-alt',
            ],
        };
    }

    public get autosuggestSuggestions(): Array<AutosuggestDataInterface<MatchInterface>>
    {
        return [
            {
                data: this
                    .suggestions
                    .slice(
                        0,
                        5,
                    ),
            },
        ];
    }

    public async created(): Promise<void>
    {
        return Promise
            .all(
                [
                    this.trigger(),
                    this.onAutosuggestInput(this.url),
                ],
            )
            .then(voidPromise);
    }

    public searchWithoutCache(): void
    {
        this.withoutCache = true;

        this.$refs.submit.click();
    }

    public getSuggestionValue(
        suggestion: AutosuggestSuggestionInterface<MatchInterface>,
    ): string
    {
        return suggestion
            .item
            .folder
            .replace(
                '/var/www',
                'http://ysera.jls.dom',
            )
            .replace(
                /(\d{4})\/(\d{4})-(\d{2})\//,
                '$1$2$3.',
            );
    }

    public async onAutosuggestInput(
        search: string,
    ): Promise<void>
    {
        if (this.cancelTokenDatalist !== null) {
            this.cancelTokenDatalist.cancel('Operation canceled due to new request.');
        }

        this.url = search;

        this.cancelTokenDatalist = Axios.CancelToken.source();

        const promise: Promise<void> = this.url.length < 4
            ? Promise.resolve()
            : client
                .request<Array<MatchInterface>>(
                    {
                        cancelToken: this.cancelTokenDatalist.token,
                        method     : 'GET',
                        params     : {
                            url: encodeURI(this.url),
                        },
                        url        : buildUrl('/suggestions'),
                    },
                )
                .then(
                    (
                        response: AxiosResponse<Array<MatchInterface>>,
                    ): void => {
                        this.suggestions = response.data;
                    },
                )
                .catch(
                    (
                        error: Error,
                    ): void => {
                        this.suggestions = [];

                        throw error;
                    },
                );

        return promise
            .finally(
                (): void => {
                    this.cancelTokenDatalist = null;
                },
            );
    }

    public autosuggestRenderSuggestion(
        suggestion: AutosuggestSuggestionInterface<MatchInterface>,
    ): string
    {
        return suggestion
            .item
            .folder
            .replace(
                '/var/www/',
                '',
            )
            .replace(
                /(\d{4})\/(\d{4})-(\d{2})\//,
                '$1 $3 ',
            );
    }

    public async onAutosuggestSelected(
        selected: AutosuggestSuggestionInterface<MatchInterface> | null,
    ): Promise<void>
    {
        if (selected !== null) {
            this.url = this.getSuggestionValue(selected);

            this.$refs.submit.click();
        }

        return Promise.resolve();
    }

    public async onSubmit(): Promise<void>
    {
        return this.url === this.urlDefault
            ? this.trigger()
            : this
                .$router
                .push(
                    {
                        query: {
                            url: this.url,
                        },
                    },
                )
                .then(voidPromise);
    }

    public async trigger(): Promise<void>
    {
        if (this.url !== '') {
            this.$emit(
                'search',
                this.url,
                this.withoutCache,
            );
        }

        this.withoutCache = false;

        return Promise.resolve();
    }
}
