import {createGuard} from 'App/dependencies/vue/plugins/router/guard';
import {store}       from 'App/dependencies/vue/plugins/vuex';
import {
    NavigationGuard,
    Route,
}                    from 'vue-router';

/**
 *
 * @type {}
 */
export const isAuthenticatedGuard: NavigationGuard = createGuard(
    async(
        to: Route,
    ): Promise<void> => {
        return new Promise<void>(
            (
                resolve: () => void,
                reject: (
                    error: Error,
                ) => void,
            ): void => {
                let canPass: boolean = true;

                if (to.meta.authenticated === true) {
                    const isAuthenticated: boolean = store.getters['auth/isAuthenticated'];

                    if (!isAuthenticated) {
                        canPass = false;
                    }
                }

                if (canPass) {
                    resolve();
                } else {
                    const error = new Error(
                        'you are not authenticated',
                    );
                    error.name = '401';

                    reject(error);
                }
            },
        );
    },
);
