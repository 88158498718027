















































































































































import {
    BooleanUtil,
    EmailUtil,
}                       from 'App/components/util';
import {AuthMixin}      from 'App/mixins';
import {PostsComponent} from 'App/views/auth/info/the-main';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            BooleanUtil,
            EmailUtil,
            PostsComponent,
        },
    },
);

@Component
export default class TheMainComponent
    extends mixins(
        definition,
        AuthMixin,
    )
{
}
