











import {DataInterface}  from 'App/views/search/error';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        data(): DataInterface
        {
            return {
                code: this.error.name,
            };
        },
        props: {
            error: {
                required: true,
                type    : Error,
            },
        },
    },
);

@Component
export default class ErrorComponent
    extends mixins(definition)
{
}
