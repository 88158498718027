











import {UrlUtil}        from 'App/components/util';
import {url}            from 'App/components/util/salesforce/config';
import {default as Vue} from 'vue';
import {
    default as Component,
    mixins,
}                       from 'vue-class-component';

const definition = Vue.extend(
    {
        components: {
            UrlUtil,
        },
        props     : {
            value: {
                required: true,
                type    : String,
            },
        },
    },
);

@Component
export default class SalesforceOpportunityUtil
    extends mixins(definition)
{
    public get url(): string
    {
        return `${url}/Opportunity/${this.value}/view`;
    }
}
