import { render, staticRenderFns } from "./the-main.component.vue?vue&type=template&id=cd2c6824&scoped=true&"
import script from "./the-main.component.vue?vue&type=script&lang=ts&"
export * from "./the-main.component.vue?vue&type=script&lang=ts&"
import style0 from "./the-main.component.vue?vue&type=style&index=0&id=cd2c6824&lang=scss&scoped=true&"
import style1 from "./the-main.component.vue?vue&type=style&index=1&id=cd2c6824&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd2c6824",
  null
  
)

export default component.exports