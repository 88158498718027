import {StateInterface as RootStateInterface} from 'App/dependencies/vue/plugins/vuex';
import {
    error,
    StateInterface,
}                                             from 'App/dependencies/vue/plugins/vuex/modules/error';
import {
    ActionContext,
    Module,
}                                             from 'vuex';

/**
 *
 * @type {{mutations: {next: (state: StateInterface, payload: (Error | null)) => void, current: (state: StateInterface, payload: (Error | null)) => void}, state: {next: null, current: null}, getters: {current: (state: StateInterface) => (Error | null)}, actions: {next: <StateInterface, StateInterface>, current: <StateInterface, StateInterface>, sync: (context: <StateInterface, StateInterface>) => Promise<void>}, namespaced: boolean}}
 */
export const errorModule: Module<StateInterface, RootStateInterface> = {
    actions   : {
        current: error('current'),
        next   : error('next'),
        sync   : async(
            context: ActionContext<StateInterface, RootStateInterface>,
        ): Promise<void> => {
            context.commit(
                'current',
                context.state.next,
            );
            context.commit(
                'next',
                null,
            );

            return Promise.resolve();
        },
    },
    getters   : {
        current: (
            state: StateInterface,
        ): Error | null => {
            return state.current;
        },
    },
    mutations : {
        current: (
            state: StateInterface,
            payload: Error | null,
        ): void => {
            state.current = payload;
        },
        next   : (
            state: StateInterface,
            payload: Error | null,
        ): void => {
            state.next = payload;
        },
    },
    namespaced: true,
    state     : {
        current: null,
        next   : null,
    },
};
